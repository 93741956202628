import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { forwardRef, useCallback, useMemo, useState } from "react";

import CustomSelectField from "components/Common/FormElements/CustomSelectFieldAsync";

import generateTransition from "utils/generateTransition";
import { generatePlaceholderStyles } from "utils/misc";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const BACKGROUND = "#18274b";
const FOCUSED_BACKGROUND = "#17274b";

const baseStyles = {
  blackText: {
    [ScreenSizes.lgAndAbove]: {
      color: colours.black,
    },
  },
  container: {
    position: "relative",
    width: "100%",
    height: "2.7rem",
    maxWidth: "100%",
    transition: "ease-out 0.2s",

    [ScreenSizes.lgAndAbove]: {
      transition: "ease-out 0.2s",
      maxHeight: "46px",
      height: "100%",
      zIndex: 1000,
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    outline: "none",
    background: BACKGROUND,
    borderRadius: 6,
    padding: "0",
    width: "100%",
    border: "1px solid transparent",
    paddingLeft: "1rem",

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 6,
      background: "inherit",
      border: `1px solid ${colours.lightGreyBorder}`,
    },
  },
  whiteSearchBorder: {
    background: colours.white,
    [ScreenSizes.lgAndAbove]: {
      background: colours.white,
      border: `1px solid ${colours.lightGreyBorder}`,
    },
  },
  searchFocus: {},
  searchFocusBorder: {},
  input: {
    ...gStyles.fontRegular,
    ...gStyles.textEllipsis,
    border: 0,
    outline: 0,
    fontSize: "1.125rem",
    padding: ".5em .8em",
    width: "100%",
    background: "none",
    color: colours.white,
    cursor: "text",
    paddingLeft: "0.5rem",

    [ScreenSizes.lgAndAbove]: {},

    ...generatePlaceholderStyles({
      ...gStyles.avalonMedium,
      fontSize: "1rem",
      color: "rgba(177,156,196,1)",
    }),
    "::placeholder": {
      color: colours.greyishBlueDarker,
      opacity: 0.8,
    },
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: ".8rem",
    padding: "0 .175rem",
    color: colours.white,
    fontSize: "1.3em",
    maxWidth: "1.25em",
    cursor: "pointer",
    lineHeight: 1,
    transition: generateTransition({
      target: "visual",
      targets: ["transform"],
      speed: "150ms",
    }),
    opacity: 0.8,
    transform: "scale(0.8, 0.8)",
    transformOrigin: "50% 50%",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1rem",
      opacity: 1,
    },
  },
  iconFocussed: {
    transition: "ease-out 0.2s",
    [ScreenSizes.lgAndAbove]: {
      transition: "ease-out 0.2s",
      color: "var(--color-neutral-d3)",
    },
  },
  iconHovered: {
    transition: "ease-out 0.2s",
    [ScreenSizes.lgAndAbove]: {
      transition: "ease-out 0.2s",
      color: "var(--color-neutral-d3)",
    },
  },
  typeSelect: {
    marginRight: "1rem",
    minWidth: "6.706rem",
  },
};

const whiteStyles = {
  search: {
    background: "white",
  },
  input: {
    color: colours.white,
    ":focus": {
      color: colours.black,
    },
    ...generatePlaceholderStyles({
      ...gStyles.fontRegularItalic,
      color: "var(--color-neutral-d5)",
      fontSize: "0.9375rem",
      [ScreenSizes.lgAndAbove]: {
        ...gStyles.avalonMedium,
        fontSize: "1rem",
        color: "var(--color-neutral-d2)",
      },
    }),
  },
  icon: {
    color: "var(--color-neutral-d3)",
    [ScreenSizes.lgAndAbove]: {
      color: colours.white,
    },
  },
};

const focusedStyles = {
  icon: {
    transform: "scale(1, 1)",
    opacity: 1,
    transition: generateTransition({
      target: "visual",
      targets: ["transform"],
      speed: "150ms",
    }),

    ...getHoverQuery({
      transform: "scale(1.1,1.1)",
    }),
  },
};

const blueFocusedStyles = {
  search: {
    background: FOCUSED_BACKGROUND,

    ...getHoverQuery({
      background: FOCUSED_BACKGROUND,
    }),
  },
};

const whiteFocusedStyles = {
  input: {
    ...generatePlaceholderStyles({
      ...gStyles.fontRegularItalic,
      color: "var(--color-neutral-d5)",
      fontSize: "0.9375rem",
      [ScreenSizes.lgAndAbove]: {
        fontSize: "1rem",
      },
    }),
  },
  icon: {
    color: colours.white,
  },
};

const typeSelectStyles = {
  inputField: {
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: 13,
    border: "none",
    paddingTop: ".3rem",
    paddingBottom: 0,
    paddingRight: 10,
    paddingLeft: "1.1rem",

    ...getHoverQuery({
      boxShadow: "none",
    }),
    ":focus": {
      opacity: 0.8,
    },
  },
  label: {
    paddingRight: "1.2rem",
  },
  selectArrow: {
    paddingRight: 0,
    marginTop: ".3rem",
  },
};

const typeSelectActiveStyles = {
  inputField: {
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: 13,
    border: "none",
    paddingTop: ".3rem",
    paddingBottom: 0,
    paddingRight: 10,
    paddingLeft: "1.1rem",

    [ScreenSizes.lgAndAbove]: {
      color: colours.black,
    },

    ...getHoverQuery({
      boxShadow: "none",
    }),
    ":focus": {
      opacity: 0.8,
    },
  },
  label: {
    paddingRight: "1.2rem",
  },
  selectArrow: {
    paddingRight: 0,
    marginTop: ".3rem",
  },
};

const popoutStyles = {
  contentWrapper: {
    marginLeft: ".9rem",
  },
};

const typeSelectMenuStyles = {
  width: "100px",
};

const TYPE_OPTIONS = {
  all: {
    value: "all",
    label: "Everything",
  },
  podcast: {
    value: "podcast",
    label: "Podcasts",
  },
  episode: {
    value: "episode",
    label: "Episodes",
  },
  creator: {
    value: "creator",
    label: "Creators",
  },
  userlist: {
    value: "userlist",
    label: "Lists",
  },
  user: {
    value: "user",
    label: "Users",
  },
  tag: {
    value: "tag",
    label: "Tags",
  },
};

const SearchField = (props) => {
  const {
    showCloseWhenTextEntered,
    placeholder,
    getInputProps,
    onCloseClick,
    searching,
    showCloseWhenEmpty,
    showSpinningIconOnSearching,
    searchStyles,
    white,
    onBlur,
    onFocus,
    forwardedRef,
    onTypeChange,
    types: passedTypes,
    selectedType,
    showTypeSelect,
    inputProps: passedInputProps,
    altSearchIcon,
    dataId,
    ariaLabel,
    isOpen,
    switchToWhiteStyles,
    renderInputExtra,
    onKeyDown,
    avoidIconEffect,
    renderExtraIconWithSearchTip,
    searchIconOnly,
    containerRef,
    renderTopSearchResults,
  } = props;

  const [focused, setFocused] = useState(false);
  const [hoverRef, isHovered] = useHover();

  const mergedStyles = useMemo(
    () => [
      baseStyles,
      searchStyles && searchStyles,
      white && whiteStyles,
      focused && !avoidIconEffect && focusedStyles,
      !white && focused && blueFocusedStyles,
      white && focused && whiteFocusedStyles,
    ],
    [focused, searchStyles, white, avoidIconEffect]
  );
  const { styles } = useStyles(mergedStyles, props);

  const types = useMemo(() => {
    if (passedTypes && passedTypes.length > 0) {
      return ["all", ...passedTypes].map((type) => TYPE_OPTIONS[type]);
    }

    return Object.values(TYPE_OPTIONS);
  }, [passedTypes]);

  const handleFocus = useCallback(
    (e) => {
      setFocused(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      if (containerRef && containerRef.current.contains(e.relatedTarget)) {
        // The relatedTarget is the element that received focus after the blur.
        // If it's still within the containerWrapper, don't setFocused to false.
        setFocused(true);

        return;
      }

      setFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur, containerRef]
  );

  const hasOpenValue = typeof isOpen === "boolean";

  const handlePreventBubbleOnEscape = useCallback(
    (event) => {
      if (onKeyDown) {
        onKeyDown(event);
      }

      if (
        event.keyCode === 27 &&
        ((event.type === "keydown" && hasOpenValue && isOpen) ||
          (event.type !== "keydown" && focused))
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [hasOpenValue, isOpen, focused, onKeyDown]
  );

  const inputProps = getInputProps({
    onKeyDown: handlePreventBubbleOnEscape,
    onKeyPress: handlePreventBubbleOnEscape,
    ...(ariaLabel && { "aria-label": ariaLabel, "aria-labelledby": undefined }),
    ...passedInputProps,
  });
  const textEntered = inputProps.value ? inputProps.value.length > 0 : false;

  const { searchIcon, searchIconSpin, searchIconOnClick } = useMemo(() => {
    let icon = faSearch;
    let spinIcon = false;
    let onIconClick = null;

    if (searching && showSpinningIconOnSearching) {
      icon = faCircleNotch;
      spinIcon = true;
    } else if (textEntered) {
      if (showCloseWhenTextEntered) {
        icon = searchIconOnly ? faSearch : faTimes;
        onIconClick = onCloseClick;
      } else {
        icon = altSearchIcon || faSearch;
      }
    } else if (showCloseWhenEmpty) {
      icon = searchIconOnly ? faSearch : faTimes;
      onIconClick = onCloseClick;
    } else {
      icon = altSearchIcon || faSearch;
    }

    return {
      searchIcon: icon,
      searchIconSpin: spinIcon,
      searchIconOnClick: onIconClick,
    };
  }, [
    searching,
    showSpinningIconOnSearching,
    textEntered,
    showCloseWhenEmpty,
    showCloseWhenTextEntered,
    onCloseClick,
    altSearchIcon,
    searchIconOnly,
  ]);

  const selectStlyes = useMemo(() => {
    if (focused || switchToWhiteStyles) {
      return typeSelectActiveStyles;
    }
    return typeSelectStyles;
  }, [focused, switchToWhiteStyles]);

  const renderTypeSelect = () => (
    <CustomSelectField
      className={css(styles.typeSelect)}
      options={types}
      onChange={onTypeChange}
      value={selectedType}
      styles={selectStlyes}
      menuStyles={typeSelectMenuStyles}
      popoutStyles={popoutStyles}
      ariaLabel="Choose search type"
      focused={focused || isHovered}
    />
  );
  return (
    <div
      ref={containerRef}
      onBlur={containerRef && handleBlur ? handleBlur : undefined}
    >
      <div
        ref={hoverRef}
        className={css(
          styles.container,
          styles.search,
          (focused || (isHovered && !avoidIconEffect) || isOpen) &&
            styles.searchFocus,

          switchToWhiteStyles && styles.whiteSearchBorder,
          focused && styles.searchFocusBorder
        )}
      >
        <FontAwesomeIcon
          data-id="search-field-icon"
          className={css(
            styles.icon,
            (focused || isOpen) && styles.iconFocussed,
            ((focused && !textEntered) || isOpen) &&
              styles.iconFocussedWithText,
            switchToWhiteStyles && styles.blackText
          )}
          onClick={searchIconOnClick}
          onKeyDown={(e) =>
            eventIsFieldTrigger(e) && searchIconOnClick && searchIconOnClick(e)
          }
          icon={searchIcon}
          spin={searchIconSpin}
          fixedWidth
        />

        <input
          {...inputProps}
          className={css(
            styles.input,
            (switchToWhiteStyles || isOpen) && styles.FloatingStles
          )}
          type="text"
          onFocus={handleFocus}
          onBlur={
            !containerRef && inputProps?.onBlur ? inputProps?.onBlur : undefined
          }
          placeholder={placeholder}
          ref={forwardedRef}
          data-id={dataId}
          aria-label="Full name"
        />
        {renderInputExtra &&
          renderInputExtra({
            focused,
            textEntered,
            switchToWhiteStyles,
            isOpen,
          })}
        {showTypeSelect && (
          <span className={css(styles.filterContainer)}>
            {(focused || isHovered || isOpen) && renderTypeSelect()}
          </span>
        )}

        {renderExtraIconWithSearchTip &&
          renderExtraIconWithSearchTip({
            textEntered,
            switchToWhiteStyles,
            eventIsFieldTrigger,
            searchIconOnClick,
            focused,
            isOpen,
            searchIconSpin,
            setFocused,
          })}
      </div>
      {renderTopSearchResults &&
        renderTopSearchResults({
          textEntered,
          focused,
          setFocused,
        })}
    </div>
  );
};

SearchField.propTypes = {
  getInputProps: PropTypes.func,
  showCloseWhenTextEntered: PropTypes.bool,
  showSpinningIconOnSearching: PropTypes.bool,
  searchStyles: PropTypes.object,
  white: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  onCloseClick: PropTypes.func,
  searching: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  showCloseWhenEmpty: PropTypes.bool,
  switchToWhiteStyles: PropTypes.bool,
  onTypeChange: PropTypes.func,
  types: PropTypes.array,
  selectedType: PropTypes.string,
  showTypeSelect: PropTypes.bool,
  inputProps: PropTypes.object,
  altSearchIcon: PropTypes.node,
  ariaLabel: PropTypes.string,
  dataId: PropTypes.string,
  isOpen: PropTypes.any,
  onKeyDown: PropTypes.func,
  avoidHover: PropTypes.bool,
  renderExtraIconWithSearchTip: PropTypes.func,
  searchIconOnly: PropTypes.bool,
};

SearchField.defaultProps = {
  getInputProps: (props) => props,
  showCloseWhenTextEntered: true,
  showCloseWhenEmpty: false,
  showSpinningIconOnSearching: false,
  searchStyles: undefined,
  white: false,
  onFocus: null,
  onBlur: null,
  placeholder: null,
  searching: false,
  forwardedRef: () => undefined,
  onTypeChange: null,
  types: null,
  selectedType: null,
  showTypeSelect: false,
  inputProps: {},
  altSearchIcon: null,
  ariaLabel: undefined,
  dataId: "input-search-field",
  isOpen: null,
};

SearchField.displayName = "SearchField";

const ForwardRefSearchField = (props, ref) => (
  <SearchField {...props} forwardedRef={ref} />
);

export default forwardRef(ForwardRefSearchField);
